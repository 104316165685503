import { Link } from "gatsby";
import React from "react";

function Footer() {
  return (
    <footer className="min-h-96 blue text-white lg:flex p-4 pt-12 lg:mt-16">
      <div className="flex flex-col">
        <h2 className="font-bold text-xl mb-8">Populære produkter</h2>
        <Link className="mb-4" to="/andet/odin-r900-romaskine">
          Odin R900 Romaskine
        </Link>
        <Link className="mb-4" to="/andet/odin-s900-spinningcykel-kinomap--zwift-bike">
          Odin S900 Spinningcykel
        </Link>
        <Link className="mb-4" to="/andet/odin-r650-romaskine-m-kinomap">
          Odin R650 Romaskine
        </Link>
        <Link className="mb-4" to="/andet/odin-c500-crosstrainer">
          Odin C500 Crosstrainer
        </Link>
        <Link className="mb-12 lg:mb-4" to="/andet/odin-b800-bluetooth-motionscykel">
          Odin B800 Motionscykel
        </Link>
      </div>
      <div className="flex flex-col lg:ml-32">
        <h2 className="font-bold text-xl mb-8">Mest læste artikler</h2>
        <Link className="mb-4" to="/blog/øvelser-med-exertube">
          Øvelser med Exertube
        </Link>
        <Link className="mb-4" to="/blog/kom-i-form-paa-en-crosstrainer">
          Kom i form på en crosstrainer
        </Link>
        <Link className="mb-4" to="/blog/kom-nemmere-op-paa-titusinde-skridt">
          Kom nemmere op på 10.0000 skridt
        </Link>
        <Link className="mb-12 lg:mb-4 underline" to="/blog">
          Læs alle artikler
        </Link>
      </div>
      <div className="flex flex-col lg:ml-32">
        <h2 className="font-bold text-xl mb-8">Links</h2>
        <Link className="mb-4" to="/sitemap.xml">
          Sitemap
        </Link>
        <Link className="mb-4" to="/cookies">
          Cookies & Politik
        </Link>
        <Link className="mb-4" to="/blog">
          Blog
        </Link>
        <a className="mb-8" href="mailto:jonaskaask@gmail.com">
          Kontakt
        </a>
      </div>
    </footer>
  );
}

export default Footer;
